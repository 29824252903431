import React from 'react'

const Loader = () => {
  return (
    
      <div id="global-loader">
<div className="whirly-loader"> </div>
</div>
    
  )
}

export default Loader
