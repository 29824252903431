import React from "react";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import AdminService from "../services/admin.services";
/* eslint-disable-next-line react/jsx-no-bind */

const Profile = () => {
  // AdminService.getProfile().then(
  //   (res) => {
  //     console.log(res);
  //   },
  //   (error) => {
  //     const resMessage =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       error.message ||
  //       error.toString();
  //     toast.error(resMessage);
  //   }
  // );

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head" />
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <img
                      src="assets/img/customer/customer5.jpg"
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <Link to="#">
                        <img src="assets/img/icons/edit-set.svg" alt="img" />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2>William Castillo</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
                <div className="ms-auto">
                  <Link to="#" className="btn btn-submit me-2">
                    Save
                  </Link>
                  <Link to="#" className="btn btn-cancel">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" placeholder="William" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" placeholder="Castilo" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" placeholder="william@example.com" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text" placeholder="+1452 876 5432" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>User Name</label>
                  <input type="text" placeholder="+1452 876 5432" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Password</label>
                  <div className="pass-group">
                    <input type="password" className=" pass-input" />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Link to="#" className="btn btn-submit me-2">
                  Submit
                </Link>
                <Link to="#" className="btn btn-cancel">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
