import React from 'react'
import { toast } from 'react-toastify';


const About = () => {
  const notify = () => toast.success("This is an alert message!");

  return (
    <div>
      <button onClick={notify}>Show Alert</button>
    </div>

   
  )
}

export default About