// Header.js

import React, { useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PowerIcon } from "@heroicons/react/24/outline";
import AuthContext from "../AuthContext";
import { Link } from "react-router-dom";

import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";

const userNavigation = [{ name: "SignOut", href: "/login" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ toggleSidebar }) => {
  const authContext = useContext(AuthContext);

  return (
    <header className="bg-blue-500 py-3 px-4 flex justify-between items-center">
      <button
        onClick={toggleSidebar}
        className="text-white focus:outline-none lg:auto"
      >
        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="hidden md:flex items-center">
        <Link to="/" className="text-white text-xm font-semibold text-center">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KGP-CENTER</span>
        </Link>
      </div>

      <div className="hidden md:flex items-center space-x-4">
        <div className="ml-4 flex items-center md:ml-6">
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src={require("../assets/logo.png")}
                  alt="profile"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-orange-400 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(
                          active ? "bg-orange-200" : "",
                          "block px-4 py-2 text-sm text-gray-100"
                        )}
                      >
                        <span onClick={() => authContext.signout()}>
                          <PowerIcon className="h-4 w-4 inline-flex items-center font-bold" />{" "}
                          {item.name}
                        </span>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {/* Placeholder for additional header content */}
    </header>
  );
};

export default Header;
