import React  from "react";

const Dashboard = () => {
 
 
  return (
    <>
      <div className="content">      
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="dash-widget">
              <div className="dash-widgetimg">
                <span>
                  <img src="assets/img/icons/dash1.svg" alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  $
                  <span className="counters" data-count={307144.0}>
                    $307,144.00
                  </span>
                </h5>
                <h6>Total Purchase Due</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="dash-widget dash1">
              <div className="dash-widgetimg">
                <span>
                  <img src="assets/img/icons/dash2.svg" alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  $
                  <span className="counters" data-count={4385.0}>
                    $4,385.00
                  </span>
                </h5>
                <h6>Total Sales Due</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="dash-widget dash2">
              <div className="dash-widgetimg">
                <span>
                  <img src="assets/img/icons/dash3.svg" alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  $
                  <span className="counters" data-count="385656.50">
                    385,656.50
                  </span>
                </h5>
                <h6>Total Sale Amount</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="dash-widget dash3">
              <div className="dash-widgetimg">
                <span>
                  <img src="assets/img/icons/dash4.svg" alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  $
                  <span className="counters" data-count={40000.0}>
                    400.00
                  </span>
                </h5>
                <h6>Total Sale Amount</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>100</h4>
                <h5>Customers</h5>
              </div>
              <div className="dash-imgs">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
</svg>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das1">
              <div className="dash-counts">
                <h4>100</h4>
                <h5>Suppliers</h5>
              </div>
              <div className="dash-imgs">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
</svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>100</h4>
                <h5>Purchase Invoice</h5>
              </div>
              <div className="dash-imgs">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>105</h4>
                <h5>Sales Invoice</h5>
              </div>
              <div className="dash-imgs">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
