import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import Home from "./pages/Home";
import About from "./pages/About";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import AuthService from "./services/auth.services";
import Profile from "./pages/Profile";
import NoPageFound from "./pages/NoPageFound";
import AuthContext from "./AuthContext";
import ProtectedWrapper from "./ProtectedWrapper";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
// import hasRole from "./common/HasRole";

const App = () => {
  const [user, setUser] = useState("");
  const [loader, setLoader] = useState(true);
  let currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        setUser(currentUser);
        setLoader(false);
      }, 1000);
    } else {
      setUser("");
      setLoader(false);
    }
  }, [currentUser]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  const signout = () => {
    setUser(null);
    AuthService.logout();
  };

  let value = { user, signin, signout };

  if (loader) {
    return <Loader />;
  }

  

  return (
    <>
      <AuthContext.Provider value={value}>
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/"
              element={
                <ProtectedWrapper>
                  <Layout />
                </ProtectedWrapper>
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="*" element={<NoPageFound />} />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </>
  );
};

export default App;
