import axios from "axios";
import BaseServices from "./baseUrl";

const login = async (mobile) => {
  const response = await axios.post(BaseServices.BASE_URL + "auth/login", {
    mobile,
  });
  return response.data;
};

const otpVerify = async (mobile, otp) => {
  const response = await axios.post(BaseServices.BASE_URL + "auth/otpVerify", {
    mobile,
    otp,
  });
  const _response = response.data;
  if (_response.data.openId) {
    localStorage.setItem("user", JSON.stringify(_response.data));
    localStorage.setItem("token", JSON.stringify(_response.data.token));
  }
  return _response.data;
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.clear();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getToken = () => {
  return JSON.parse(localStorage.getItem("token"));
};

const AuthService = {
  login,
  otpVerify,
  logout,
  getCurrentUser,
  getToken,
};

export default AuthService;
