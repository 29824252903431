// src/components/Layout.js
import React, { useState } from "react";
import Header from "./Header";
import SidebarDrawer from "./SidebarDrawer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="flex flex-col h-screen main-wrapper">
        <Header toggleSidebar={toggleSidebar} />
        <div className="flex flex-1 overflow-hidden">
          <SidebarDrawer
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
          <main className="flex-1 overflow-y-auto p-4">
            <Outlet />
          </main>
        </div>
        {/* Footer */}
        <footer className="bg-gray-200 py-1 px-2">
          <div className="container mx-auto text-center">
            <p className="text-sm text-gray-600">
              &copy; 2024 Nesting Probe. All rights reserved.
            </p>
          </div>
        </footer>
        {/* Loader */}       
      </div>
    </>
  );
};

export default Layout;
