import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.services";
import AuthContext from "../AuthContext";
import { toast } from "react-toastify";
import OtpInput from "./OtpInput";
import CountdownTimer from "./CountdownTimer";

const Login = () => {
  const form = useRef();
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const otpDuration = 120; // 2 minutes

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    AuthService.login(mobile).then(
      (data) => {
        if (data.success) setLoading(false);
        setShowOtpForm(true);
        toast.success(data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        toast.error(resMessage);
      }
    );
  };

  const maskingMobile = (phoneNumber) => {
    let subNum = phoneNumber.toString().substring(0, 6);
    subNum = subNum + "XXXX";
    return subNum;
  };

  const handleOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    AuthService.otpVerify(mobile, otp).then(
      () => {
        let currentUser = AuthService.getCurrentUser();
        authContext.signin(currentUser.token, () => {
          navigate("/");
        });
      },
      (error) => {
        console.log(error.response);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                <section className="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                  <img
                    src="assets/img/logo.png"
                    className="items-center h-50 w-50"
                    alt="img"
                  />
                </section>
                {!showOtpForm ? (
                  <>
                    <div className="login-userheading text-center	">
                      <h3>Authentication with OTP</h3>
                      <p>Please login to your account</p>
                    </div>

                    <form onSubmit={handleSubmit} ref={form}>
                      <div className="form-login">
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-login">
                        <label>Username : </label>
                        <div className="form-addons">
                          <input
                            type="text"
                            className="border rounded"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            placeholder="Enter your mobile"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-login">
                        <button className="btn btn-login" disabled={loading}>
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>SEND OTP</span>
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleVerifyOTP} ref={form}>
                      <div className="login-userheading text-center">
                        <h3>OTP Verification</h3>
                        <h4>
                          Enter the 6-digit OTP sent to your register mobile
                          number.
                        </h4>
                        <span className="font-bold">
                          +91 {maskingMobile(mobile)}
                        </span>
                      </div>
                      <div className="form-login">
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-login">
                        <OtpInput length={6} onChange={handleOTPChange} />
                      </div>
                      <div className="flex justify-center text-center mt-3">
                        <CountdownTimer duration={otpDuration} />
                      </div>
                      <div className="form-login">
                        <button className="btn btn-login" disabled={loading}>
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span> Verify OTP</span>
                        </button>
                      </div>
                     
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="login-img">
              <img src="assets/img/login.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
