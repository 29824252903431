import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CountdownTimer = ({ duration }) => {
  const [countdown, setCountdown] = useState(duration); // Initial duration in seconds
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        setExpired(true);
        clearInterval(intervalId);
      }
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [countdown]);

  // Convert seconds into minutes and seconds format
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    <div>
      {expired ? (
        <Link className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
          <span className="font-bold">Resend OTP</span>
          <i className="bx bx-caret-right ml-1"></i>
        </Link>
      ) : (
        <span className="flex items-center text-orange-500 font-bold">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds} min
        </span>
      )}
    </div>
  );
};

export default CountdownTimer;
